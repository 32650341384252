export default function moneyFormat(x){
	return x.toLocaleString('it-IT', {style : 'currency', currency : 'VND'});
}

export function numberFormat(x){
	let data = parseFloat(x).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
	if(data.endsWith('.00')){
		data = data.slice(0, -3);
	}
	return data != 'NaN' ? data : '--'
}