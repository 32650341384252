<template>
  <div>
    <v-card-title class="pl-6 pt-0 d-flex">
      {{ title }}
      <div style="height: 33px" v-if="!hideAddProduct">
        <v-tooltip bottom v-if="!showImportExcel">
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              class="mx-2"
              width="25px"
              height="25px"
              fab
              small
              outlined
              size
              color="var(--primary-color)"
              @click="addProduct"
            >
              <v-icon size="19px"> mdi-plus </v-icon>
            </v-btn>
          </template>
          <span>Thêm sản phẩm</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
              color="var(--primary-color)"
              @click="importExcel"
              :loading="loadingImportExcel"
            >
              <v-icon>mdi-microsoft-excel</v-icon>
            </v-btn>
          </template>
          <span>Thêm qua tệp tin excel</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
              @click="downloadExcel"
              :loading="loadingDownloadExcel"
            >
              <v-icon>mdi-download-outline</v-icon>
            </v-btn>
          </template>
          <span>Tải tệp tin excel mẫu</span>
        </v-tooltip>

        <input
          type="file"
          hidden
          ref="product_import"
          accept=".xlsx, .xls"
          @change="onChange"
        />
      </div>
    </v-card-title>
    <v-layout column class="pl-6 pr-6">
      <v-row>
        <v-col cols="3" class="zero-vertical" v-if="!hideSelectItem">
          <div class="label-form">Sản phẩm</div>
          <v-autocomplete
            prepend-inner-icon="mdi-format-list-bulleted"
            v-model="selectedData"
            :items="productsList"
            outlined
            dense
            :item-text="
              (item) => [item.ten, item.ma].filter((x) => x).join(' - ')
            "
            item-value="id"
            @change="onChangeProducts"
            return-object
          >
          </v-autocomplete>
        </v-col>

        <v-col
          cols="12"
          class="zero-vertical"
          :key="`table_product_${keyChange}`"
        >
          <!-- ********* Product Table ********* -->
          <CustomTable
            class="mb-2"
            :headers="headers"
            :items="ProductData"
            hide-default-footer
            :page.sync="page"
            @change-page="changePage"
            classPaging="mt-2"
          >
            <template v-slot:[`item.url_image`]="{ item }">
              <img
                height="50px"
                width="50px"
                :src="
                  item.url_image
                    ? item.url_image
                    : item.image && getImageUrl(item.image.link)
                "
                v-if="item.image || item.url_image"
              />
            </template>
            <template v-slot:[`item[keyPrice]`]="{ item }">
              {{ formatnumber(item[keyPrice]) }}
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon
                small
                color="green"
                class="mr-2"
                @click="showPriceHistory(item)"
              >
                mdi-chart-line
              </v-icon>
              <v-icon small class="mr-2" @click="editItem(item)">
                mdi-pencil
              </v-icon>
              <v-icon
                color="red"
                v-if="!readonly"
                small
                @click="deleteItem(item)"
              >
                mdi-delete
              </v-icon>
            </template>
          </CustomTable>
          <!-- ********* Product Table ********* -->
        </v-col>
      </v-row>
    </v-layout>
    <Dialog
      :title="formTitle"
      ref="dialog-product"
      :keyPrice="keyPrice"
      @submit="save"
      @close:dialog="editedIndex = -1"
      :readonly="readonly"
    />
    <!-- <DialogHistoryPrice
      title="Chi tiết giá của từng mặt hàng"
      ref="dialog-history-price"
      :keyPrice="keyPrice"
      @close:dialog="editedIndex = -1"
      readonly
    ></DialogHistoryPrice> -->
    <DialogPriceHistoryChart
      title="Lịch sử giá của mặt hàng"
      ref="dialog-price-history"
      :keyPrice="keyPrice"
      readonly
    ></DialogPriceHistoryChart>
  </div>
</template>

<script>
import { numberFormat } from "@/utils/moneyFormat";
import Dialog from "./Dialog";
import DialogHistoryPrice from "./DialogHistoryPrice";
import DialogPriceHistoryChart from "./DialogPriceHistoryChart.vue";
import { UNITS } from "@/constants/yeucaubaogia";
import { readExcel, getExcelSample } from "@/api/sanpham";
import { saveAs } from "file-saver";
export default {
  components: {
    Dialog,
    // DialogHistoryPrice,
    DialogPriceHistoryChart,
  },
  props: {
    table: String,
    title: String,
    hideSelectItem: Boolean,
    hideAddProduct: Boolean,
    showImportExcel: Boolean,
    value: Array,
    productsList: { type: Array, default: () => [] },
    keyPrice: { type: String, default: "product_price" },
    readonly: Boolean,
  },
  data: (vm) => ({
    loadingImportExcel: false,
    loadingDownloadExcel: false,
    keyChange: 1,
    UNITS,
    editedItem: {},
    dialog: false,
    dialogHistoryPrice: false,
    page: 1,
    editedIndex: -1,
    selectedData: [],
    headers: [
      {
        text: "Mã sản phẩm",
        align: "start",
        sortable: false,
        value: "product_code",
      },
      { text: "Tên sản phẩm", value: "product_name" },
      { text: "ĐVT", value: "product_unit" },
      { text: "Đơn giá (VNĐ)", value: vm.keyPrice },
      { text: "Hình ảnh", value: "url_image" },
      { text: "Ghi chú", value: "description" },
      { text: "Hành động", value: "actions", sortable: false },
    ],
  }),
  computed: {
    formTitle() {
      if (this.readonly) {
        return "Chi tiết sản phẩm";
      }
      return this.editedIndex === -1 ? "Thêm sản phẩm" : "Sửa sản phẩm";
    },
    ProductData: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    async downloadExcel() {
      try {
        this.loadingDownloadExcel = true;
        const res = await getExcelSample();
        saveAs(new Blob([res]), `file_mau.xlsx`);
      } catch (err) {
        this.errorMessages("lỗi");
      } finally {
        this.loadingDownloadExcel = false;
      }
    },
    importExcel() {
      this.$refs.product_import.click();
    },
    async onChange(e) {
      let files = e.dataTransfer ? e.dataTransfer.files : e.target.files;
      let formData = new FormData();
      formData.append("file", files[0]);
      formData.append("key_price", this.keyPrice);
      try {
        this.loadingImportExcel = true;
        const res = await readExcel(formData);
        this.ProductData = [...this.ProductData, ...res];
      } catch (e) {
        this.$store.dispatch("toastAlert/error", "Dữ liệu không hợp lệ");
      } finally {
        this.loadingImportExcel = false;
        this.$refs.product_import.value = "";
      }
    },
    errorMessages(message, timeout = 2000) {
      this.$toast.error(message, {
        position: "top-center",
        timeout,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
      });
    },
    formatnumber(number) {
      return numberFormat(number);
    },
    getImageUrl(url) {
      if (url.charAt(0) === "/") {
        url = url.substring(1);
      }
      let path = `${process.env.VUE_APP_BASE}${url}`;
      return path;
    },
    onChangeProducts(event) {
      let data = {};
      data.product_code = event.ma;
      data.product_name = event.ten;
      data.product_unit = event.don_vi_tinh;
      data.description = event.mo_ta;
      data.chat_thai_id = event.id;
      data.type = event.loai_chat_thai_id;
      console.log("data", data);
      this.ProductData.push(data);
    },
    editItem(item) {
      this.editedIndex = this.ProductData.map((x) => JSON.stringify(x)).indexOf(
        JSON.stringify(item)
      );
      this.editedItem = Object.assign({ table: this.table }, item);
      this.$refs["dialog-product"].open(this.editedItem);
    },
    showPriceHistory(item) {
      this.$refs["dialog-price-history"].open(item);
    },
    addProduct() {
      this.$refs["dialog-product"].open({});
    },
    changePage(val) {
      this.page = val;
    },
    save(dataEdit) {
      if (this.editedIndex > -1) {
        if (this.editing && dataEdit.id) {
          let data = { ...dataEdit };
          data.type = data.type && parseInt(data.type);
          data.reference_id = this.form.id;
        }
        this.keyChange++;
        this.$set(this.ProductData, this.editedIndex, dataEdit);
      } else {
        this.ProductData.push(dataEdit);
      }
    },
    async deleteItem(item) {
      this.$confirmBox.show({
        title: "Xóa sản phẩm",
        width: 500,
        body: "Bạn có chắc chắn muốn xóa sản phẩm này không?",
        disableMessage: true,
        action: async () => {
          if (this.editing && item.id) {
            // await destroy(item.id);
          }
        },
        onDone: () => {
          this.editedIndex = this.ProductData.indexOf(item);
          this.ProductData.splice(this.editedIndex, 1);
          this.$emit("changeData", this.ProductData);
        },
      });
    },
  },
};
</script>
