<template>
  <FormDialog
    :readonly="readonly"
    :title="title"
    v-model="dialogHistoryPrice"
    max-width="1000px"
    :key="`dialog_sp_${keyChange}`"
  >
    <v-container class="main-dialog">
      <!-- <v-form ref="form_detail"> -->
      <div
        v-if="productPriceList.length == 0 && productPriceList == undefined"
        class="loading"
      >
        <v-row class="mb-4" justify="center">
          <v-progress-circular indeterminate size="30"></v-progress-circular>
        </v-row>
        <v-row justify="center">
          <span>Đang tải dữ liệu...</span>
        </v-row>
      </div>
      <div v-else-if="productPriceList.length == 0" class="loading">
        <v-row justify="center">
          <span>Không có dữ liệu ! </span>
        </v-row>
      </div>
      <div v-else class="content">
        <!-- <v-data-table
          :headers="headers"
          :items="productPriceList"
          :items-per-page="20"
          hide-default-footer
          class="elevation-1"
        >
          <template v-slot:[`item.created_at`]="{ item }">
            <span>{{ getDay(item.created_at) }}</span>
          </template>
          <template v-slot:[`item.product_price_detail`]="{ item }">
            <span>{{
              getPrice(item.product_price_detail, itemHistoryPrice.id)
            }}</span>
          </template>
        </v-data-table> -->
        <HistoryPrice :price="productPriceList" />
      </div>
    </v-container>
  </FormDialog>
</template>
<script>
import { UNITS } from "@/constants/yeucaubaogia";
import { getProductPrices } from "@/api/baogia";
import HistoryPrice from "../../Dashboard/ChartRequest/HistoryPrice.vue";
export default {
  props: {
    title: String,
    keyPrice: String,
    readonly: Boolean,
  },
  components: {
    HistoryPrice,
  },
  data: () => ({
    UNITS,
    itemHistoryPrice: {},
    productPriceList: [],
    dialogHistoryPrice: false,
    keyChange: 1,
    headers: [
      { text: "Mã báo giá", value: "id" },
      { text: "Ngày báo giá", value: "created_at" },
      { text: "Giá", value: "product_price_detail" },
    ],
  }),

  methods: {
    getDay(day) {
      var dateTime = new Date(day);
      var formattedDateTime = dateTime.toLocaleString("en-GB", {
        hour: "2-digit",
        minute: "2-digit",
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      });
      return formattedDateTime;
    },
    getPrice(price, id) {
      const arr = [...price];
      const results = arr.filter((el) => el.id == id);
      return results[0].product_price;
    },
    async getAllProductTypes(id) {
      let res = await getProductPrices({
        id_product_details: id,
      });
      this.productPriceList = res.data;
    },
    open(data = {}) {
      this.keyChange++;
      this.itemHistoryPrice = { ...data };
      console.log("itemHistoryPrice: ", this.itemHistoryPrice.id);
      this.getAllProductTypes(this.itemHistoryPrice.id);
      this.dialogHistoryPrice = true;
    },
    close() {
      this.dialogHistoryPrice = false;
    },
    // async save() {
    //   // this.$refs.form_detail.validate();
    //   // if (this.$refs.form_detail.validate()) {
    //   this.$emit("submit", this.editedItem);
    //   this.close();
    //   // }
    // },
  },
};
</script>
<style scoped>
.main-dialog {
  padding: 35px 100px;
}
</style>
