var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card-title',{staticClass:"pl-6 pt-0 d-flex"},[_vm._v(" "+_vm._s(_vm.title)+" "),(!_vm.hideAddProduct)?_c('div',{staticStyle:{"height":"33px"}},[(!_vm.showImportExcel)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2",attrs:{"width":"25px","height":"25px","fab":"","small":"","outlined":"","size":"","color":"var(--primary-color)"},on:{"click":_vm.addProduct}},on),[_c('v-icon',{attrs:{"size":"19px"}},[_vm._v(" mdi-plus ")])],1)]}}],null,false,933656637)},[_c('span',[_vm._v("Thêm sản phẩm")])]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"var(--primary-color)","loading":_vm.loadingImportExcel},on:{"click":_vm.importExcel}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-microsoft-excel")])],1)]}}],null,false,2035303371)},[_c('span',[_vm._v("Thêm qua tệp tin excel")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","loading":_vm.loadingDownloadExcel},on:{"click":_vm.downloadExcel}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-download-outline")])],1)]}}],null,false,642207535)},[_c('span',[_vm._v("Tải tệp tin excel mẫu")])]),_c('input',{ref:"product_import",attrs:{"type":"file","hidden":"","accept":".xlsx, .xls"},on:{"change":_vm.onChange}})],1):_vm._e()]),_c('v-layout',{staticClass:"pl-6 pr-6",attrs:{"column":""}},[_c('v-row',[(!_vm.hideSelectItem)?_c('v-col',{staticClass:"zero-vertical",attrs:{"cols":"3"}},[_c('div',{staticClass:"label-form"},[_vm._v("Sản phẩm")]),_c('v-autocomplete',{attrs:{"prepend-inner-icon":"mdi-format-list-bulleted","items":_vm.productsList,"outlined":"","dense":"","item-text":function (item) { return [item.ten, item.ma].filter(function (x) { return x; }).join(' - '); },"item-value":"id","return-object":""},on:{"change":_vm.onChangeProducts},model:{value:(_vm.selectedData),callback:function ($$v) {_vm.selectedData=$$v},expression:"selectedData"}})],1):_vm._e(),_c('v-col',{key:("table_product_" + _vm.keyChange),staticClass:"zero-vertical",attrs:{"cols":"12"}},[_c('CustomTable',{staticClass:"mb-2",attrs:{"headers":_vm.headers,"items":_vm.ProductData,"hide-default-footer":"","page":_vm.page,"classPaging":"mt-2"},on:{"update:page":function($event){_vm.page=$event},"change-page":_vm.changePage},scopedSlots:_vm._u([{key:"item.url_image",fn:function(ref){
var item = ref.item;
return [(item.image || item.url_image)?_c('img',{attrs:{"height":"50px","width":"50px","src":item.url_image
                  ? item.url_image
                  : item.image && _vm.getImageUrl(item.image.link)}}):_vm._e()]}},{key:"item[keyPrice]",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatnumber(item[_vm.keyPrice]))+" ")]}},{key:"item.actions",fn:function(ref){
                  var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"green"},on:{"click":function($event){return _vm.showPriceHistory(item)}}},[_vm._v(" mdi-chart-line ")]),_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]),(!_vm.readonly)?_c('v-icon',{attrs:{"color":"red","small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")]):_vm._e()]}}],null,true)})],1)],1)],1),_c('Dialog',{ref:"dialog-product",attrs:{"title":_vm.formTitle,"keyPrice":_vm.keyPrice,"readonly":_vm.readonly},on:{"submit":_vm.save,"close:dialog":function($event){_vm.editedIndex = -1}}}),_c('DialogPriceHistoryChart',{ref:"dialog-price-history",attrs:{"title":"Lịch sử giá của mặt hàng","keyPrice":_vm.keyPrice,"readonly":""}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }