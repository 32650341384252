import request from "../utils/request";

export function getProductTypes(params) {
    return request({
        url: "producttypes",
        method: "get",
        params,
    });
}

export function getAllProductTypes() {
    return request({
        url: "allproducttypes",
        method: "get",
    });
}

export function addProductTypes(data) {
    return request({
        url: "producttypes",
        method: "post",
        data,
    });
}

export function editProductTypes(data) {
    return request({
        url: "producttypes",
        method: "put",
        data,
    });
}

export function deleteProductTypes(data) {
    return request({
        url: "/producttypes",
        method: "delete",
        data
    });
}