<template>
  <div>
    <v-overlay v-if="overlay">
      <v-progress-circular :size="60" indeterminate color="green lighten-4">
      </v-progress-circular>
    </v-overlay>
    <FormDialog :readonly="readonly" :title="title" v-model="showDialogHistoryChart" max-width="1000px"
      :key="`dialog_sp_${keyChange}`">
      <apexchart type="line" height="300%" :options="chartOptions" :series="series"></apexchart>
    </FormDialog>
  </div>
</template>
<script>
import { Chart } from "highcharts-vue";
import Highcharts from "highcharts";
import { getProductDetailPriceHistory } from "@/api/productdetail.api";
import * as notify from "@/utils/notify-show";
export default {
  components: {
    // highcharts: Chart,
  },
  props: {
    title: String,
    readonly: Boolean,
    keyPrice: String,
  },
  data() {
    return {
      series: [{
        name: "Giá",
        data: []
      }],
      chartOptions: {
        chart: {
          type: 'line',
          zoom: {
            enabled: true,
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          width: 2,
          curve: 'straight'
        },
        title: {
          text: '',
          align: 'center',
          floating: true,
          style: {
            fontFamily: "san-serif",
          }
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'],
            opacity: 0.5
          },
        },
        tooltip: {
          x: {
            format: "dd/MM/yyyy"
          }
        },
        xaxis: {
          title: {
            text: "Thời gian",
            floating: true,
            style: {
              fontFamily: "san-serif",
            }
          },
          margin: 10,
          type: 'datetime',
          labels: {
            datetimeFormatter: {
              year: 'yyyy',
              month: 'MM yy',
              day: 'dd MM'
            }
          }
        },
        yaxis: {
          title: {
            text: "Giá (VNĐ)",
            floating: true,
            style: {
              fontFamily: "san-serif",
            }
          },
        }
      },
      // chartOptions: {
      //   chart: {
      //     type: "line",
      //   },
      //   title: {
      //     text: "Placeholder Chart Title",
      //   },
      //   legend: {
      //     enabled: false,
      //   },
      //   tooltip: {
      //     formatter: function() {
      //       return 'Ngày: ' + Highcharts.dateFormat('%e-%m-%Y', this.x) + '<br>' +
      //               'Giá: ' + this.y;
      //     }
      //   },
      //   xAxis: {
      //     title: {
      //       text: "Thời gian",
      //     },
      //     type: "datetime",
      //     labels: {
      //       format: "{value:%d-%m-%Y}",
      //     },
      //     crosshair: true,
      //   },
      //   yAxis: {
      //     title: {
      //       text: "Giá",
      //     },
      //   },
      //   plotOptions: {
      //     series: {
      //       allowPointSelect: false,
      //     },
      //   },
      //   series: [
      //     {
      //       name: "Giá",
      //       data: [],
      //     },
      //   ],
      // },
      productDetailData: {},
      priceHistoryData: [],
      filteredPriceHistoryData: [],
      formSearch: {
        product_code: "",
        table: "",
      },
      overlay: false,
      showDialogHistoryChart: false,
      keyChange: 1,
    };
  },
  watch: {
  },
  methods: {
    open(item) {
      this.overlay = true;
      this.productDetailData = { ...item };
      this.formSearch = {
        product_code: this.productDetailData.product_code,
        table: "PRODUCT_PRICES",
      };
      this.getProductPriceHistory({ ...this.formSearch }).then(() => {
        if (this.priceHistoryData.length) {
          this.setChartOptions(
            this.productDetailData.product_name,
            this.priceHistoryData
          );
          this.overlay = false;
          this.showDialogHistoryChart = true;
        }
        else {
          this.overlay = false;
          notify.info("Sản phẩm chưa có lịch sử báo giá");
        }
      });
    },
    async getProductPriceHistory(params = {}) {
      try {
        const res = await getProductDetailPriceHistory(params);
        console.log(res.data, "log res data");
        if (res.data.length) {
          this.priceHistoryData = res.data
            .map((obj) => [Date.parse(obj.date), parseInt(obj.product_price)])
            .filter((item) => item[1])
            .sort((item1, item2) => item1[0] - item2[0]);
        }
        else {
          return;
        }
      } catch (err) {
        notify.error(err);
      }
    },
    setChartOptions(title, data) {
      this.chartOptions.title.text = title;
      this.series[0].data = [...data];
    },
  },
};
</script>
