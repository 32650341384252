import { AApi } from "@/service/AApi";

const API = new AApi(
  {
    url: "sx/chat-thais",
  },
  {
    productTemplate: {
      async request(params) {
        return this.sdk({
          url: `sx/chat-thais/template`,
          params,
          responseType: "blob",
        });
      },
    },
    productExport: {
      async request(params) {
        return this.sdk({
          url: `sx/chat-thais/export`,
          params,
          responseType: "blob",
        });
      },
    },
    productImport: {
      async request(data) {
        return this.sdk({
          url: `sx/chat-thais/import`,
          method: "post",
          data,
        });
      },
    },
    productExportError: {
      async request(data) {
        return this.sdk({
          url: `sx/chat-thais/export-error`,
          method: "post",
          data,
          responseType: "blob",
        });
      },
    },
    mergeProduct: {
      async request(data) {
        return this.sdk({
          url: `sx/chat-thais/merge-record`,
          method: "post",
          data,
        });
      },
    },
  }
);

export default API;
