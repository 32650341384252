<template>
  <div class="container">
    <div id="chart">
      <apexchart
        type="line"
        height="350"
        :options="chartOptions"
        :series="series"
      ></apexchart>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    price: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    series: [
      {
        name: "Price",
        data: [0],
      },
    ],
    chartOptions: {
      chart: {
        height: 350,
        type: "line",
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      markers: {
        size: 7,
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"],
          opacity: 0.5,
        },
      },
      xaxis: {
        categories: [""],
      },
      yaxis: {
        labels: {
          formatter: function(value) {
            if (value) {
              return value.toLocaleString("en-US") + " VND";
            } else {
              return value + " VND";
            }
          },
        },
      },
    },
    priceData: [],
  }),
  methods: {
    handleDataToShowChart(data) {
      const arr = [...data];
      const categories = [];
      const series = [];
      arr.reduce((acc, item) => {
        categories.push(`${item.id} - ${item.product_name}`);
        series.push(item.product_price);
      }, {});
      if (categories.length > 0) {
        this.chartOptions.xaxis.categories = categories;
      }
      if (series.length > 0) {
        var hasNullElement = series.some(function(value) {
          return value === null;
        });
        if (hasNullElement) {
          var updatedSeries = series.map(function(value) {
            return value === null ? 0 : value;
          });
          this.series[0].data = updatedSeries;
        } else {
          this.series[0].data = series;
        }
      }
    },
  },

  created() {
    if (this.price) {
      this.priceData = this.price[0].product_price_detail;
      this.handleDataToShowChart(this.priceData);
    }
  },
};
</script>

<style scoped>
/* .container {
  padding-right: 120px;
} */
</style>
