import request from "../utils/request";

export function getProducts(params) {
  return request({
    url: "products",
    method: "get",
    params,
  });
}
export function mergeProduct(data) {
  return request({
    url: "products/merge-products",
    method: "put",
    data,
  });
}
export function getAllProducts(params) {
  return request({
    url: "allproducts",
    method: "get",
    params,
  });
}
export function getUnmergedProducts(params) {
  return request({
    url: "unmerged-products",
    method: "get",
    params,
  })
}
export function addProducts(data) {
  return request({
    url: "products",
    method: "post",
    data,
  });
}

export function editProducts(id, data) {
  return request({
    url: `products/${id}`,
    method: "post",
    data,
  });
}

export function deletedProducts(data) {
  return request({
    url: "/products",
    method: "delete",
    data,
  });
}

export function readExcel(data) {
  return request({
    url: "/products-read-excel",
    method: "post",
    data,
  });
}
export function getExcelSample() {
  return request({
    url: `/products-excel-sample`,
    responseType: "blob",
  });
}
export function productExport(params) {
  return request({
    url: `/products-export`,
    params,
    responseType: "blob",
  });
}