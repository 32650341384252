export const Status = [
  {
    id: 1,
    name: "Mới tạo (NV KD tạo)",
  },
  {
    id: 2,
    name: "Đã tiếp nhận (NV Hậu Cần)",
  },
  {
    id: 3,
    name: "Đã xử lý (NV Hậu Cần)",
  },
  {
    id: 4,
    name: "Hủy Bỏ (NV Hậu Cần)",
  },
];
export const UNITS = ["Kg", "m3", "Chiếc", "Lần", "Tháng"];
export const StyleStatus = {
  1: "bg-primary",
  2: "bg-info",
  3: "bg-success",
  4: "bg-gray",
};
export const RequestTypeList = [
  { value: 1, name: "Yêu cầu báo giá mới" },
  { value: 2, name: "Yêu cầu báo giá thay thế" },
  { value: 3, name: "Yêu cầu báo giá bổ sung" },
];

export const TABLE_PRODUCT_PRICE_REQUESTS_DETAILS =
  "PRODUCT_PRICE_REQUESTS_DETAILS";
export const TABLE = "PRODUCT_PRICE_REQUEST";
