<template>
  <FormDialog
    :readonly="readonly"
    :title="title"
    v-model="dialog"
    max-width="900px"
    @submit="save"
    :key="`dialog_sp_${keyChange}`"
  >
    <v-container>
      <!-- <v-form ref="form_detail"> -->
      <v-row>
        <v-col xs="8" sm="8" md="8">
          <div class="label-form">Tên sản phẩm</div>
          <v-text-field
            placeholder="Tên sản phẩm"
            outlined
            dense
            prepend-inner-icon="mdi-account"
            v-model="editedItem.product_name"
            :rules="nameProductRules"
            autofocus
            :readonly="readonly"
          ></v-text-field>
        </v-col>
        <v-col xs="4" sm="4" md="4">
          <div class="label-form">Mã sản phẩm</div>
          <!-- <v-text-field
            placeholder="Mã sản phẩm"
            outlined
            dense
            disabled
            prepend-inner-icon="mdi-account"
            v-model="editedItem.product_code"
            :rules="codeProductRules"
            :readonly="readonly"
          ></v-text-field> -->
          <v-autocomplete
            prepend-inner-icon="mdi-account"
            v-model="editedItem.product_code"
            :items="filteredItems"
            outlined
            dense
            item-value="ma"
            item-text="ma - ten"
            :rules="codeProductRules"
            :readonly="readonly"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>Không tìm thấy kết quả</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-autocomplete>
        </v-col>

        <v-col xs="4" sm="4" md="4">
          <div class="label-form">Đơn vị tính</div>
          <v-text-field
            placeholder="Đơn vị tính"
            outlined
            dense
            prepend-inner-icon="mdi-account"
            v-model="editedItem.product_unit"
            :rules="unitProductRules"
            :readonly="readonly"
          ></v-text-field>
        </v-col>
        <v-col xs="4" sm="4" md="4">
          <div class="label-form">Loại sản phẩm</div>
          <v-select
            prepend-inner-icon="mdi-image-area"
            v-model="editedItem.type"
            :items="productTypesList"
            outlined
            dense
            item-text="ten"
            item-value="id"
            :rules="typeProductRules"
            :readonly="readonly"
          >
          </v-select>
        </v-col>

        <v-col xs="4" sm="4" md="4">
          <div class="label-form">Đơn giá (VNĐ)</div>
          <v-text-field
            placeholder="Đơn giá (VNĐ)"
            outlined
            dense
            prepend-inner-icon="mdi-account"
            v-model="editedItem[keyPrice]"
            type="number"
            :readonly="readonly"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col xs="6" sm="6" md="6">
          <div class="d-flex flex-column" style="height: 100%">
            <div class="label-form flex-grow-0">Hình ảnh</div>
            <ImageUpdate
              :readonly="readonly"
              width="100%"
              height="200px"
              v-model="editedItem"
            />
          </div>
        </v-col>

        <v-col xs="6" sm="6" md="6">
          <div class="label-form">Ghi chú</div>
          <v-textarea
            v-model="editedItem.description"
            placeholder="Ghi chú"
            outlined
            dense
            prepend-inner-icon="mdi-note"
            no-resize
            height="200px"
            hide-details
            :readonly="readonly"
          ></v-textarea>
        </v-col>
      </v-row>
      <!-- </v-form> -->
    </v-container>
  </FormDialog>
</template>
<script>
import { UNITS } from "@/constants/yeucaubaogia";
import loaiChatThaiAPI from "@/api/sx.loaichatthai.api";
import chatThaiAPI from "@/api/sx.chatthai.api";
import { getAllProductTypes } from "@/api/loaisanpham";
export default {
  props: {
    title: String,
    keyPrice: String,
    readonly: Boolean,
  },
  data: () => ({
    UNITS,
    editedItem: {},
    nameProductRules: [(v) => !!v || "Tên sản phẩm không thể bỏ trống"],
    codeProductRules: [(v) => !!v || "Mã sản phẩm không thể bỏ trống"],
    unitProductRules: [(v) => !!v || "Đơn vị tính không thể bỏ trống"],
    productTypesList: [],
    chatThaiSanXuat: [],
    dialog: false,
    typeProductRules: [(v) => !!v || "Loại sản phẩm không thể bỏ trống"],
    keyChange: 1,
  }),

  mounted() {
    this.getProductTypesList();
    this.getChatThaiSanXuat();
  },
  computed: {
    filteredItems() {
      return this.chatThaiSanXuat.map((item) => {
        return { ...item, ["ma - ten"]: `${item.ma} - ${item.ten}` };
      });
    },
  },
  watch: {
    dialog(val) {
      if (!val) {
        this.$emit("close:dialog");
        this.editedItem = {};
        console.log("editedItem: ", this.editedItem);
      }
    },
  },
  methods: {
    async getProductTypesList() {
      let res = await loaiChatThaiAPI.list();
      this.productTypesList = res.data;
    },

    async getChatThaiSanXuat() {
      let res = await chatThaiAPI.list();
      console.log("res: ", res);
      this.chatThaiSanXuat = res.data;
    },
    open(data = {}) {
      this.keyChange++;
      this.editedItem = { ...data };
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },
    async save() {
      // this.$refs.form_detail.validate();
      // if (this.$refs.form_detail.validate()) {
      this.$emit("submit", this.editedItem);
      this.close();
      // }
    },
  },
};
</script>
