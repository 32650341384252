import request from "../utils/request";

export function getProductDetailPriceHistory(params = {}) {
  return request({
    url: `productDetailPriceHistory`,
    method: "get",
    params,
  })
}

export function getByCustomer(id, params={}) {
  return request({
    url: `/productByCustomer/${id}`,
    method: "get",
    params,
  });
}

export function store(data) {
  return request({
    url: "productdetail",
    method: "post",
    data,
  });
}

export function update(data, id) {
  return request({
    url: `productdetail/${id}`,
    method: "post",
    data,
  });
}

export function destroy(id) {
  return request({
    url: `productdetail/${id}`,
    method: "delete",
  });
}
